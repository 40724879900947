import React from 'react'
import Icon from 'src/components/UI/MarkdownIcon'
import UTMLink from 'src/components/CallToAction/ActionButtons/UTMLink'
import VerticalCarousel from 'src/components/UI/Carousels/VerticalCarousel'
import economySectionContent from './../../assets/data/economySectionContent.json'
import useWidthResize from 'src/hooks/window/useWidth'

import { WIDTH_LG } from 'src/utils/breakpoints'
import { ItensProps } from './types'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

const Economize = ({ sendDatalayerEvent }: DataLayerProps) => {
  const windowWidth = useWidthResize(200)
  const isMobile = windowWidth < WIDTH_LG
  return (
    <section id='economize' className='bg-white py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 text-lg-center mb-5'>
            <h2 className='text-grayscale--500 fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 lh-30 fs-xl-40 lh-xl-48 mb-0'>
              O que você pode fazer com essa economia?
            </h2>
          </div>
          {isMobile
            ? (
              <div className='col-12 mb-5 mb-md-0'>
                <VerticalCarousel
                  sm={{
                    verticalItems: 2,
                    horizontalItems: 1,
                  }}
                  md={{
                    verticalItems: 2,
                    horizontalItems: 3,
                  }}
                >
                  {economySectionContent.map((item: ItensProps, index: number) => (
                    <div key={index} className='mb-5'>
                      <div className='text-center'>
                        <Icon color='orange--base' width='60' height='60' icon={item.icon} directory='v2' />
                        <p className='mb-0 mt-3 text-grayscale--400 fs-16 lh-18'>{item.text}</p>
                      </div>
                    </div>
                  ))}
                </VerticalCarousel>
              </div>
            )
            : (
              economySectionContent.map((item: ItensProps, index: number) => {
                return (
                  <div key={index} className='col-lg-4 mb-5'>
                    <div className='text-center'>
                      <Icon color='orange--base' width='60' height='60' icon={item.icon} directory='v2' />
                      <p className='mb-0 mt-3 text-grayscale--400 fs-16 lh-18'>{item.text}</p>
                    </div>
                  </div>
                )
              })
            )
          }
          <div className='col-12 text-center mt-n4'>
            <UTMLink
              text='Simular minha economia'
              link='SIMULADOR_PORTABILIDADE_IMOBILIARIO'
              campanha='site-pf'
              btncolor='btn--lg btn-orange--extra rounded-2 lh-17 fs-14 fw-600 text-none'
              dobra='dobra_05'
              sectionName='O que você pode fazer com essa economia?'
              sendDatalayerEvent={sendDatalayerEvent}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Economize
