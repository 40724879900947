import styled from 'styled-components'
import { orange } from 'src/styles/colors'

export const PortabilityStepSection = styled.section`
  .step-with-border {
    border-bottom: 1px solid #DEDFE4;
  }
`

export const PortabilityStepNumber = styled.div`
  background: ${orange.extra};
  border-radius: 50%;
  margin-right: 24px;
  min-width: 30px;
  text-align: center;
  padding: 8px;
`
