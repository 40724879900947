import styled from 'styled-components'
import { device } from './../../../../../../styles/breakpoints'

export const PerfectForYou = styled.section`

  .react-multi-carousel-list {

    ul.react-multi-carousel-track {

      @media ${device.desktopLG} {
        width: 100%;
      }

      @media ${device.desktopLG} {
        margin-top: 80px;
        margin-bottom: 80px;
      }

      li {

        @media ${device.desktopXL} {
          min-width: 284px;
        }

        &:last-child {

          @media ${device.desktopLG} {
            margin-top: 70px;
          }
        }

        .perfect-card {
          min-height: 177px;

          @media ${device.tablet} {
            min-height: 210px;
          }
        }
      }
    }
  }
`

export const CardContainer = styled.div`
  height: 205px;
  min-width: 264px;
`
