import React from 'react'

import Icon from 'src/components/UI/MarkdownIcon'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import UTMLink from 'src/components/CallToAction/ActionButtons/UTMLink'

import taxesSectionContent from '../../assets/data/taxesSectionContent.json'
import { CardContainer } from './style'
import useWidthResize from 'src/hooks/window/useWidth'
import { WIDTH_XL } from 'src/utils/breakpoints'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

type ListProps = {
  icon: string;
  title: string;
  description?: string;
}

type TaxesProps = {
  title: string;
  list: ListProps[];
}

const CompareAsTaxas = ({ sendDatalayerEvent }: DataLayerProps) => {
  const windowWidth = useWidthResize(200)
  const isDesktop = windowWidth >= WIDTH_XL

  return (
    <section id='compare-as-taxas' className='bg-gray py-5'>
      <div className='container my-lg-5'>
        <div className='row'>
          <div className='col-12 text-left text-lg-center'>
            <h2 className='text-grayscale--500 fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-48 mb-3'>
              Compare as taxas e escolha a sua
            </h2>
            <p className='fs-16 lh-20 text-grayscale--400 mb-0'>
              No Inter, é você quem escolhe a taxa que melhor se encaixa no seu financiamento.
            </p>
          </div>
        </div>
        {isDesktop ? (
          <div className='my-4 row align-items-center justify-content-center'>
            {taxesSectionContent.map((instruction: TaxesProps) => (
              <div className='col-4' key={instruction.title}>
                <CardContainer>
                  <header className='mt-2 mb-4 px-2 border-bottom'>
                    <h4 className='fs-20 text-grayscale--500 fw-600'>{instruction.title}</h4>
                  </header>
                  <ul className='list-unstyled'>
                    {instruction.list.map((listItem: ListProps, index: number) => (
                      <li key={listItem.title} className={`d-flex align-items-start ${index < (instruction.list.length - 1) ? 'mb-4' : ''}`}>
                        <span><Icon color='orange--base' width='24' height='24' icon={listItem.icon} directory='v2' /></span>
                        <div className='ml-3 mt-2'>
                          <h5 className='fs-16 text-grayscale--500 fw-600'>{listItem.title}</h5>
                          {listItem.description && <p className='fs-16 lh-18 text-gray--700 mb-0'>{listItem.description}</p>}
                        </div>
                      </li>
                        ))}
                  </ul>
                </CardContainer>
              </div>
            ))
          }
          </div>
        ) : (
          <DefaultCarousel
            containerClass='mx-n2 mx-md-0'
            md={{ items: 2 }}
            lg={{ items: 2 }}
          >
            {taxesSectionContent.map((instruction: TaxesProps) => (
              <div key={instruction.title} className='bg-white border rounded-3 p-3 p-xl-4 mx-2 h-100'>
                <header className='mt-2 mb-4 px-2 border-bottom'>
                  <h4 className='fs-20 text-grayscale--500 fw-600'>{instruction.title}</h4>
                </header>
                <ul className='list-unstyled'>
                  {instruction.list.map((listItem: ListProps, index: number) => (
                    <li key={listItem.title} className={`d-flex align-items-start ${index < (instruction.list.length - 1) ? 'mb-4' : ''}`}>
                      <span><Icon color='orange--base' width='24' height='24' icon={listItem.icon} directory='v2' /></span>
                      <div className='ml-3'>
                        <h5 className='fs-16 text-grayscale--500 fw-600'>{listItem.title}</h5>
                        {listItem.description && <p className='fs-16 lh-18 text-gray--700 mb-0'>{listItem.description}</p>}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              ))
            }
          </DefaultCarousel>
        )}

        <div className='row mt-4 mt-lg-0'>
          <div className='col-12 text-center'>
            <UTMLink
              text='Simular minhas parcelas'
              link='SIMULADOR_PORTABILIDADE_IMOBILIARIO'
              campanha='site-pf'
              btncolor='btn--lg btn-orange--extra rounded-2 lh-17 fs-14 fw-600 text-none'
              dobra='dobra_04'
              sectionName='Compare as taxas e escolha a sua'
              sendDatalayerEvent={sendDatalayerEvent}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CompareAsTaxas
