import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import Img from 'gatsby-image'
import { orange } from 'src/styles/colors'
import { ContainerBlack } from './style'
import useWidth from 'src/hooks/window/useWidth'
import UTMLink from 'src/components/CallToAction/ActionButtons/UTMLink'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

import usePageQuery from '../../pageQuery'
import { WIDTH_LG, WIDTH_MD } from 'src/utils/breakpoints'

const data = [
  {
    icon: 'airport',
    text: 'Sala VIP em Aeroportos',
  },
  {
    icon: 'gift',
    text: 'Programa Mastercard Surpreenda',
  },
  {
    icon: 'couch',
    text: 'Viagens com conforto',
  },
  {
    icon: 'seguros',
    text: 'Seguro médico em viagens',
  },
  {
    icon: 'handbag',
    text: 'Proteção de Bagagem',
  },
  {
    icon: 'ticket',
    text: 'Aproveite o Priceless Cities',
  },
]

type CartaoData = {
  icon: string;
  text: string;
}

const CartaoBlack = ({ sendDatalayerEvent }: DataLayerProps) => {
  const imageData = usePageQuery()
  const width = useWidth()

  return (
    <ContainerBlack className='py-5 d-md-flex position-relative justify-content-md-between align-items-center'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-lg-6 col-xl-5 pr-lg-0'>
            { width < WIDTH_MD &&
              <Img
                className='mb-4 ml-3' fluid={imageData.backgroundBlackMobile.fluid}
                alt='Cartao inter black'
              />
            }
            <h3 className='fs-24 lh-30 fs-lg-40 lh-lg-50 text-grayscale--500 mb-3'>
              Faça a portabilidade e aproveite seu cartão Inter Black recheado de benefícios!
            </h3>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-grayscale--400 mb-md-5 mb-lg-0'>
              No Inter, seu cartão Black também te dá acesso a seguros que garantem sua tranquilidade, onde quer que você esteja.
            </p>
            { width < WIDTH_MD &&
              <div className='mt-4'>
                {data.map((item: CartaoData) => (
                  <div className='d-flex align-items-center my-4' key={item.icon}>
                    <OrangeIcon icon={item.icon} size='MD' color={orange.extra} />
                    <p className='mb-0 ml-3 fs-16 lh-19 fw-700 text-grayscale--500'>{item.text}</p>
                  </div>
                  ))}
              </div>
            }
            { width < WIDTH_MD || width >= WIDTH_LG
              ? (
                <UTMLink
                  text='Solicite seu cartão Black Inter'
                  link='SIMULADOR_PORTABILIDADE_IMOBILIARIO'
                  campanha='site-pf'
                  btncolor='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none mt-4'
                  dobra='dobra_07'
                  sectionName='Faça a portabilidade e aproveite seu cartão Inter Black recheado de benefícios!'
                  sendDatalayerEvent={sendDatalayerEvent}
                />
              )
              : ''
            }
          </div>
          { width >= WIDTH_MD &&
            <div className='col-md-6'>
              {data.map((item: CartaoData) => (
                <div className='d-flex align-items-center my-4' key={item.icon}>
                  <OrangeIcon icon={item.icon} size='MD' color={orange.extra} />
                  <p className='mb-0 ml-2 fs-16 lh-19 fw-700 text-grayscale--500'>{item.text}</p>
                </div>
              ))}
            </div>
          }
        </div>
      </div>
    </ContainerBlack>
  )
}

export default CartaoBlack
