import React from 'react'
import Img from 'gatsby-image'
import UTMLink from 'src/components/CallToAction/ActionButtons/UTMLink'

import usePageQuery from '../../pageQuery'
import taxes from '../../assets/data/taxas.json'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

const NovaTaxaJurosPoupanca = ({ sendDatalayerEvent }: DataLayerProps) => {
  const data = usePageQuery()
  const url = 'https://inter.co/pra-voce/financiamento-imobiliario/residencial/taxa-bonificada/'

  return (
    <section id='nova-taxa-juros-poupanca' className='bg-gray py-5'>
      <div className='container py-md-4'>
        <div className='row'>
          <div className='row justify-content-center justify-content-lg-between align-items-center'>
            <div className='col-11 mx-auto col-md-5 col-xl-6 pl-xl-4 mb-2 mb-lg-0'>
              <Img fluid={data.novaTaxaJurosPoupanca.fluid} alt='Um símbolo cifrão com uma mão e várias moedas ' />
            </div>
            <div className='col-12 col-md-6 mt-4 mt-md-0'>
              <h2 className='fw-600 fs-24 lh-28 fs-lg-32 lh-lg-37 fs-xl-40 lh-xl-47 text-grayscale--500 mb-3 pr-lg-5'>
                <span className='d-xl-block'>Aproveite uma das </span>
                <span className='d-md-block'>melhores taxas do </span>
                <span className='d-xl-block'>mercado e diminua</span> suas parcelas!
              </h2>
              <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-grayscale--400'>
                Este é o momento ideal para aproveitar a nossa taxa bonificada a partir de {taxes.taxaPoupanca} a.a. + TR.
                <strong>Uma taxa de juros reduzida</strong>, feita especialmente para os clientes que se enquadram nas condições
                de relacionamento.
                <a
                  href={url}
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_02',
                      section_name: 'Aproveite uma das melhores taxas do mercado e diminua suas parcelas!',
                      element_action: 'click button',
                      element_name: 'Saiba mais!',
                      redirect_url: url,
                     })
                  }}
                > Saiba mais!
                </a>
              </p>
              <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-grayscale--400 mb-4 pr-lg-5'>
                Lembrando que essa taxa atua apenas como um corretor do saldo devedor do contrato e não compõe o valor total dos juros cobrados mensalmente.
              </p>
              <UTMLink
                text='Simular agora'
                link='SIMULADOR_PORTABILIDADE_IMOBILIARIO'
                campanha='site-pf'
                btncolor='btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none'
                dobra='dobra_02'
                sectionName='Aproveite uma das melhores taxas do mercado e diminua suas parcelas!'
                sendDatalayerEvent={sendDatalayerEvent}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NovaTaxaJurosPoupanca
