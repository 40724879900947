import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      tragaSeuContratoParaOInter: imageSharp(fluid: {originalName: { regex: "/traga-seu-contrato-para-o-inter/" }}) {
        fluid(maxWidth: 455, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      novaTaxaJurosPoupanca: imageSharp(fluid: {originalName: { regex: "/nova-taxa-juros-poupanca/" }}) {
        fluid(maxWidth: 455, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      primeiroPasso: imageSharp(fluid: {originalName: { regex: "/portabilidade-primeiro-passo/" }}) {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      segundoPasso: imageSharp(fluid: {originalName: { regex: "/portabilidade-segundo-passo/" }}) {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      terceiroPasso: imageSharp(fluid: {originalName: { regex: "/portabilidade-terceiro-passo/" }}) {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      quartoPasso: imageSharp(fluid: {originalName: { regex: "/portabilidade-quarto-passo/" }}) {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      contratoPortabilidade: imageSharp(fluid: {originalName: { regex: "/contrato-portabilidade/" }}) {
        fluid(maxWidth: 455, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      backgroundBlackMobile: imageSharp(fluid: {originalName: { regex: "/cartao-black-mobile/" }}) {
        fluid(maxWidth: 455, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
