import React, { useState, useEffect } from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import Popup from 'src/components/Popup'
import useSessionStorage from 'src/hooks/window/useSessionStorage'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidthResize from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import pageContext from './pageContext.json'

import { WIDTH_XL } from 'src/utils/breakpoints'
import { Wrapper } from './style'

import Header from './sections/header/_index'
import NovaTaxaJurosPoupanca from './sections/nova-taxa-juros-poupanca/_index'
import PerfeitoParaVoce2 from './sections/perfeito-pra-voce/_index2'
import CompareAsTaxas from './sections/compare-as-taxas/_index'
import Economize from './sections/economize/_index'
import TragaSeuContrato from './sections/traga-seu-contrato/_index'
import CartaoBlack from './sections/cartao-black/_index'
import Faq from './sections/faq/_index'

const PortabilidadeImobiliario = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const domReady = useDomReady()
  const windowWidth = useWidthResize(200)
  const isDesktop = windowWidth > WIDTH_XL

  const [ popup, setPopup ] = useSessionStorage('@hide-bancointer-popup-portabilidade', false)
  const [ isPopupOpen, setIsPopupOpen ] = useState(false)
  const [ isModalPopupOpen, setIsModalPopupOpen ] = useState(true)

  useEffect(() => {
    !isModalPopupOpen && setPopup(true)
  }, [ isModalPopupOpen ])

  const handleOnMouseLeave = () => {
    setIsPopupOpen(true)
  }

  domReady && document.addEventListener('mouseleave', handleOnMouseLeave)

  const PopupExitPage = domReady && !popup && isDesktop && (
    <Modal isModalOpen={isPopupOpen} setIsModalOpen={setIsModalPopupOpen} locationToRender={document.body}>
      <Popup closeModal={() => setIsPopupOpen(false)} setPopup={setPopup} pageName='portabilidade' />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {PopupExitPage}
        <Header sendDatalayerEvent={sendDatalayerEvent} />
        <NovaTaxaJurosPoupanca sendDatalayerEvent={sendDatalayerEvent} />
        <PerfeitoParaVoce2 />
        <CompareAsTaxas sendDatalayerEvent={sendDatalayerEvent} />
        <Economize sendDatalayerEvent={sendDatalayerEvent} />
        <TragaSeuContrato sendDatalayerEvent={sendDatalayerEvent} />
        <CartaoBlack sendDatalayerEvent={sendDatalayerEvent} />
        <Faq />
      </Layout>
    </Wrapper>
  )
}

export default PortabilidadeImobiliario
