import styled from 'styled-components'

export const CardContainer = styled.div`
  max-width: 440px;
  min-height: 257px;
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  height: 216px;
  width: 100%;
`
