import React from 'react'
import Img from 'gatsby-image'
import UTMLink from 'src/components/CallToAction/ActionButtons/UTMLink'
import VerticalCarousel from 'src/components/UI/Carousels/VerticalCarousel'
import HowTo from 'src/components/StructuredData/HowTo'

import pageContext from '../../pageContext.json'
import usePageQuery from '../../pageQuery'

import { ItensProps } from './types'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

import { PortabilityStepSection, PortabilityStepNumber } from './style'

const TragaSeuContrato = ({ sendDatalayerEvent }: DataLayerProps) => {
  const data = usePageQuery()
  const howToData = pageContext.structuredData.howTo

  return (
    <PortabilityStepSection id='traga-seu-contrato' className='bg-grayscale--100 py-5'>
      <div className='container mb-lg-5'>
        <div className='row'>
          <div className='col-12 col-md-5 col-lg-6 d-flex justify-content-center align-items-center justify-content-md-start mb-5 mb-md-0'>
            <Img fluid={data.contratoPortabilidade.fluid} alt='Cliente do Banco Inter mexendo no celular' />
          </div>
          <div className='col-12 col-md-6 offset-md-1 offset-lg-0 col-xl-5 px-xl-0'>
            <h2 className='text-grayscale--500 fw-600 fs-25 lh-30 fs-lg-32 lh-lg-40 fs-xl-39 lh-xl-48 mb-4'>
              Traga seu contrato para o Inter
            </h2>
            <HowTo data={howToData}>
              <VerticalCarousel
                containerClass='mb-4 mb-lg-0'
                sm={{
                      verticalItems: 2,
                      horizontalItems: 1,
                }}
                md={{
                      verticalItems: 2,
                      horizontalItems: 1,
                }}
                lg={{
                      verticalItems: 4,
                      horizontalItems: 1,
                }}
                xl={{
                      verticalItems: 4,
                      horizontalItems: 1,
                }}
              >
                {howToData.content.map((item: ItensProps, index: number) => (
                  <div key={item.position} className={`d-flex ${index < (howToData.content.length - 1) ? 'step-with-border' : ''} align-items-start pb-3 mb-3`}>
                    <PortabilityStepNumber className='fs-14 lh-14 text-white'>{item.position}</PortabilityStepNumber>
                    <div>
                      <p className='text-grayscale--400 fs-14 fw-600 lh-16 mb-2'>{item.name}</p>
                      <p className='text-grayscale--400 fs-14 fw-400 lh-16 mb-0'>{item.text}</p>
                    </div>
                  </div>
                    ))}
              </VerticalCarousel>
            </HowTo>
            <UTMLink
              text='Simular agora'
              link='SIMULADOR_PORTABILIDADE_IMOBILIARIO'
              campanha='site-pf'
              btncolor='btn--lg btn-orange--extra rounded-2 fs-14 fw-600 text-none'
              dobra='dobra_06'
              sectionName='Traga seu contrato para o Inter'
              sendDatalayerEvent={sendDatalayerEvent}
            />
          </div>
        </div>
      </div>
    </PortabilityStepSection>
  )
}

export default TragaSeuContrato
