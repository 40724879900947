import React from 'react'
import perfectForYouSectionContent from '../../assets/data/perfectForYouSectionContent.json'
import Icon from '../../../../../../components/UI/MarkdownIcon'
import { CardContainer } from './style'

import useWidthResize from '../../../../../../hooks/window/useWidth'
import { WIDTH_LG } from 'src/utils/breakpoints'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import { ItemProps } from './types'

const PerfeitoParaVoce = () => {
  const windowWidth = useWidthResize(200)
  const isDesktop = windowWidth >= WIDTH_LG

  return (
    <div className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fw-600 fs-24 lh-28 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-3 text-center'>
              Perfeito pra você, com mais facilidades
            </h2>
            <p className='fs-16 lh-19 fs-md-18 lh-22 mb-4 text-grayscale--400 text-center'>
              Pesquise com a gente a melhor oportunidade para reduzir as parcelas do seu financiamento. No Inter, o processo de contratação
              é digital, o que traz agilidade para você economizar mais.
            </p>
          </div>
        </div>
        {isDesktop ? (
          <div className='row mt-4 justify-content-center align-items-center'>
            {perfectForYouSectionContent.map((item: ItemProps) => (
              <div className='col-lg-4 col-xl-3' key={item.title}>
                <CardContainer className='bg-gray rounded-3 px-4 py-3 mb-4 mx-1 mx-md-2 mx-lg-3 align-items-center perfect-card'>
                  <Icon width='24' height='24' icon={item.icon} directory='v2' color='orange--base' />
                  <p className='fs-16 lh-19 text-grayscale--500 fw-600 pt-4'>{item.title}</p>
                  <p className='fs-14 lh-17 text-gray--700 mb-0'>{item.description}</p>
                </CardContainer>
              </div>
            ))
          }
          </div>
        ) : (
          <DefaultCarousel>
            {perfectForYouSectionContent.map((item: ItemProps) => (
              <CardContainer key={item.title} className='bg-gray rounded-3 px-4 py-3 mb-4 mx-1 mx-md-2 mx-lg-3 align-items-center perfect-card'>
                <Icon width='24' height='24' icon={item.icon} directory='v2' color='orange--base' />
                <p className='fs-16 lh-19 text-grayscale--500 fw-600 pt-4'>{item.title}</p>
                <p className='fs-14 lh-17 text-gray--700 mb-0'>{item.description}</p>
              </CardContainer>
            ))
          }
          </DefaultCarousel>
        )}
      </div>
    </div>
  )
}

export default PerfeitoParaVoce
