import React from 'react'

import FAQ from 'src/components/Faq'

import { FAQSection } from './style'

import pageContext from '../../pageContext.json'

const Faq = () => {
  return (
    <FAQSection className='bg-grayscale--100 py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-4'>
            <h3 className='text-grayscale--500 fw-600 fs-24 lh-24 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-48 mb-3'>
              Dúvidas sobre a Portabilidade de Financiamento
            </h3>
          </div>
          <FAQ
            columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
            id='portabilidade-imobiliario'
            answerData={pageContext.structuredData.faq}
            searchBg='white'
          />
        </div>
      </div>
    </FAQSection>
  )
}

export default Faq
