import styled from 'styled-components'
import { device } from './../../../../../../styles/breakpoints'

export const ContentHeader = styled.section`
  min-height: 270px;

  @media ${device.tablet} {
    align-items: center;
    min-height: 350px;
  }

  @media ${device.desktopLG} {
    min-height: calc(100vh - 73px);
  }

  .gatsby-image-wrapper {
    float: right;
    max-width: 456px;
  }
`
export const ScrollLink = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  text-align: center;
  line-height: 1.67;
  transform: translateX(-50%);
  width: auto;
`
