import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

import BgDesktopLg from '../../assets/images/cartao-black-desktop-lg.png'
import BgGradient from '../../assets/images/cartao-black-gradiente.png'
import BgTablet from '../../assets/images/cartao-black-tablet.png'

export const ContainerBlack = styled.section`
  @media ${device.tablet} {
    height: 580px;
    background-image: url(${BgTablet});
    background-repeat: no-repeat;
    background-position: top right;
  } 

  @media ${device.desktopLG} {
    height: 517px;
    background-image: url(${BgDesktopLg});
    background-repeat: no-repeat;
    background-position: top right;
  }
  @media ${device.desktopXL} {
    height: 551px;
    background-image: url(${BgGradient});
    background-repeat: no-repeat;
    background-position: top right;
  }
`
