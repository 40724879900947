import React from 'react'
import Img from 'gatsby-image'

import useWidth from 'src/hooks/window/useWidth'
import Icon from 'src/components/UI/MarkdownIcon'
import UTMLink from 'src/components/CallToAction/ActionButtons/UTMLink'
import ScrollTo from 'src/components/ScrollTo'

import { WIDTH_MD } from 'src/utils/breakpoints'

import usePageQuery from '../../pageQuery'

import { ContentHeader, ScrollLink } from './style'
import { DataLayerProps } from 'src/hooks/useDataLayer/types'

const Header = ({ sendDatalayerEvent }: DataLayerProps) => {
  const Width = useWidth()
  const data = usePageQuery()

  return (
    <ContentHeader id='traga-seu-contrato' className='pt-4 pb-5 pt-md-0 d-md-flex position-relative'>
      <div className='container'>
        <div className='row justify-content-md-between align-items-center'>
          { Width >= WIDTH_MD &&
            <div className='col-12 col-md-5 offset-md-1 offset-lg-0 col-lg-5 offset-xl-1 pl-lg-0 order-md-last mb-2 mb-md-0'>
              <Img fluid={data.tragaSeuContratoParaOInter.fluid} alt='Momentos de felicidade de um casal' />
            </div>
          }
          <div className='col-12 col-md-6 col-xl-5'>
            <h1 className='fw-600 fs-22 lh-28 fs-md-24 lh-md-28 fs-lg-32 lh-lg-40 fs-xl-47 lh-xl-55 fw-600 text-grayscale--500 mb-3'>
              Traga seu contrato e garanta parcelas menores
            </h1>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 mb-4 text-grayscale--400'>
              Você pode portar seu contrato economizando nas suas parcelas.<span className='fw-600'> Simule agora!</span>
            </p>
            <UTMLink
              text='Simular minha portabilidade'
              link='SIMULADOR_PORTABILIDADE_IMOBILIARIO'
              campanha='site-pf'
              btncolor='btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none'
              dobra='dobra_01'
              sectionName='Traga seu contrato e garanta parcelas menores'
              sendDatalayerEvent={sendDatalayerEvent}
            />
          </div>
        </div>
      </div>
      <ScrollTo
        to='#nova-taxa-juros-poupanca'
        section='dobra_01'
        sectionName='Traga seu contrato e garanta parcelas menores'
        elementName='Conheça mais!'
        styles='text-center d-none d-lg-block'
      >
        <ScrollLink>
          <p className='text-orange--base mb-0 fs-18 lh-22 fw-600'>Conheça mais</p>
          <Icon color='orange--base' width='24' height='24' icon='navigation/chevron-down' directory='v2' />
        </ScrollLink>
      </ScrollTo>
    </ContentHeader>
  )
}

export default Header
